<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-card flat>
                            <v-card-text>
                                <v-list dense>
                                    <v-row>
                                        <v-col cols="12" sm="6" class="my-0 py-0">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span v-if="item.containerNo">{{
                                                            item.containerNo
                                                        }}</span><span v-else>-</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Container No.
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="my-0 py-0">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span v-if="item.sealNo">{{ item.sealNo }}</span><span
                                                            v-else>-</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle> Seal No. </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="my-0 py-0">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span v-if="item.booking.carrierReferenceNumber">{{
                                                            item.booking.carrierReferenceNumber
                                                        }}</span><span v-else>-</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle> Booking No. </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="my-0 py-0">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span v-if="item.booking.contractNumber">{{
                                                            item.booking.contractNumber
                                                        }}</span><span v-else>-</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Contract No.
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="my-0 py-0">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span v-if="item.booking.shipperReferenceNumbers">{{
                                                            item.booking.shipperReferenceNumbers
                                                        }}</span><span v-else>-</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Reference No.
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="my-0 py-0" v-if="item.customerContainerRef">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span>{{
                                                            item.customerContainerRef
                                                        }}</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Customer Ref.
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6" class="my-0 py-0">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span>{{ formatNumber(sumGrossWeight.toFixed(2)) }} Kg</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Gross Weight
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="my-0 py-0">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <span>{{ formatNumber(sumNettWeight.toFixed(2)) }} Kg</span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle> Nett Weight </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                </v-list>
                                <!-- <v-list dense>
                                    <v-divider></v-divider>
                                    <v-subheader style="font-size: 16px">
                                        <v-icon color="secondary" class="mr-2">local_shipping</v-icon>
                                        Transport
                                    </v-subheader>
                                    <v-divider></v-divider>
                                    <v-list-item>

                                    </v-list-item>
                                </v-list> -->
                                <v-list dense>
                                    <v-divider></v-divider>
                                    <v-subheader style="font-size: 16px" :key="dataSourceKey">
                                        <v-icon color="secondary" class="mr-2">bar_chart</v-icon>
                                        Data Source(s)
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="dataSourceChange" :loading="savingDataSource"
                                            @click="updateDataSource()" color="blue" icon>
                                            <v-icon>save</v-icon>
                                        </v-btn>
                                    </v-subheader>
                                    <v-divider></v-divider>
                                    <v-progress-linear v-if="loadingDataSource" color="primary"
                                        indeterminate></v-progress-linear>
                                        <!-- <small style="color:grey" v-if="$store.state.user.tagId == 3">
                                        You do not have permission to change the data source, please contact your System Administrator for assistance.
                                        </small> -->
                                    <v-list-item v-for="source in availableSources" :key="source.id"
                                        style="border-bottom: 0.5px solid grey">
                                        <v-list-item-content @click="viewSource(source)" style="cursor: pointer">
                                            <v-list-item-title>
                                                {{ source.type }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="source.locationCode">
                                                <v-icon class="mr-2" color="grey" small>location_on</v-icon> {{
                                                    source.locationCode }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="source.transactionTime">
                                                <v-icon class="mr-2" color="grey" small>schedule</v-icon> {{
                                                    source.transactionTime }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="text-wrap">
                                                <v-icon class="mr-2" color="grey" small>description</v-icon>{{ source.name
                                                }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-switch color="success" v-model="source.active"
                                                @change="checkDataSourceChanges()"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="!loadingDataSource && availableSources.length == 0">
                                        <v-list-item-content class="text-center">
                                            <span style="color: grey; font-size: 12px">No data sources found</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="9" :key="eventKey">
                        <v-card flat>
                            <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="secondary">category</v-icon>
                                Product Summary</v-subheader>
                            <v-divider></v-divider>
                            <v-card-text>

                                <ProductBreakdownTable :headers="productSummaryHeaders" :items="productSummary"
                                    :tableKey="productKey" :loading="loadingContainerBreakdown" :height="'100%'" />
                            </v-card-text>
                        </v-card>
                        <v-card flat v-if="eventData && eventData.events">
                            <v-subheader style="font-size: 16px"><v-icon class="mr-2"
                                    color="secondary">gps_not_fixed</v-icon> Container
                                Tracking</v-subheader>
                                <v-divider></v-divider>
                            <v-card-text class="mt-0 pt-0">
                                <DCSAContainerTracking class="mt-1 pt-1" v-if="eventData.eventType == 'DCSA'"
                                    :events="eventData.events" :vertical="false" />
                                <ContainerMilestone class="mt-1 pt-1" v-else :events="eventData.events" :vertical="false" />
                            </v-card-text>
                        </v-card>
                        <v-card flat>
                                    <v-subheader style="font-size: 16px"><v-icon class="mr-2"
                                            color="secondary">thermostat</v-icon> Monitoring
                                    </v-subheader>
                                    <v-divider></v-divider>
                            <v-card-text :key="telemetryKey">
                                   
                                    <v-card flat :loading="loadingTelemetry">
                                    <v-card-text>
                                        <ContainerTelemetry v-if="!loadingTelemetry && telemetry.length>0" :data="telemetry" />
                                            <v-list-item v-else-if="!loadingTelemetry && telemetry.length==0">
                                            <v-list-item-content>
                                                <span style="font-size: 12px; color: grey">No monitoring data</span>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-card-text>
                                    </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>

        <!-- Data Source Modal -->
        <v-dialog v-model="sourceModal" fullscreen persistent>
            <v-card v-if="sourceModal">
                <v-card-title>
                    Manage Data Source
                    <v-spacer></v-spacer>
                    <v-btn text @click="sourceModal = false, selectedContainer=null">X</v-btn>
                </v-card-title>
                <v-card-subtitle>
                    {{ sourceItem.name }}
                    <br />
                    <v-icon class="mr-2" small color="grey">label</v-icon> {{ sourceItem.type }}
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <!-- <v-col cols="12">
            </v-col> -->
                        <v-col cols="12" v-if="sourceItem.sourceType == 'stockFile'">
                            <v-data-table :items="sourceItem.contents" :headers="importHeaders" height="82vh" fixed-header
                                dense disable-pagination hide-default-footer></v-data-table>
                        </v-col>
                        <v-col cols="12" v-else>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-list dense>
                                        <v-subheader style="font-size: 16px">Containers</v-subheader>
                                        <v-divider></v-divider>
                                        <v-list-item v-for="container in sourceItem.stockFileContainers" :key="container.id" @click="selectedContainer = container"
                                        :style="{'border-left': selectedContainer && selectedContainer.id == container.id?'3px solid var(--v-primary-base)':''}"
                                        >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ container.containerNo }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" sm="10">
                                    <v-data-table v-if="selectedContainer" :items="selectedContainer.contents" :headers="importHeaders" height="82vh" fixed-header
                                dense disable-pagination hide-default-footer></v-data-table>
                                </v-col>

                            </v-row>
                            
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import ContainerTelemetry from '../../Telemetry/ContainerTelemetry.vue'
import DCSAContainerTracking from '../../ContainerTracking/DCSA.vue'
import ContainerMilestone from '../../ContainerTracking/ContainerMilestone.vue'
import ProductBreakdownTable from '../../SystemComponents/ProductBreakdownTable.vue'
import axios from 'axios'
export default {
    props: ['item'],
    components: {
        ContainerTelemetry,
        DCSAContainerTracking,
        ContainerMilestone,
        ProductBreakdownTable
    },
    data: () => ({
        activeSources: [],
        availableSources: [],
        dataSourceChange: false,
        dataSourceKey: 4000,
        eventData: null,
        eventKey: 1000,
        importHeaders: [],
        loadingContainerBreakdown: false,
        loadingDataSource: false,
        loadingTelemetry: false,
        productSummary: null,
        productKey: 2000,
        productSummaryHeaders: [
            {
                text: "Product",
                value: "productName",
                align: "center",
            },
            {
                text: "Variety",
                value: "varietyName",
                align: "center",
            },
            {
                text: "Pack Code",
                value: "packCode",
                align: "center",
            },
            {
                text: "Grade/Class",
                value: "gradeClassCode",
                align: "center",
            },

            {
                text: "Count/Size",
                value: "countSize",
                align: "center",
            },
            {
                text: "No. Cartons",
                value: "noCartons",
                align: "center",
            },
            {
                text: "Percentage",
                value: "percentage",
                align: "center",
            },
            {
                text: "Price per Carton",
                value: "cartonPrice",
                align: "center",
            },
            {
                text: "Price per KG",
                value: "kgPrice",
                align: "center",
            },
        ],
        savingDataSource: false,
        selectedContainer: null,
        sourceModal: false,
        sourceItem: {},
        telemetry: [],
        telemetryKey: 3000

    }),
    watch: {
        'item.id': {
            immediate: true,
            handler(val) {
                this.dataSourceChange = false
                this.eventData = null
                this.productSummary = null
                if (val) { this.load() }
            }
        }
    },
    async created() {
        this.importHeaders = await this.$API.getMappingHeaders()
        this.importHeaders = this.importHeaders.map(x => ({
            text: x.name,
            value: x.field,
            align: 'center'
        }))
    },
    computed: {
        sumNettWeight() {
            return this.item.containerPallets.filter((x) => x.nettWeight)
                .map((x) => parseFloat(x.nettWeight))
                .reduce((a, b) => a + b, 0);
        },
        sumGrossWeight() {
            return this.item.containerPallets.filter((x) => x.grossWeight)
                .map((x) => parseFloat(x.grossWeight))
                .reduce((a, b) => a + b, 0);
        }
    },
    methods: {
        checkDataSourceChanges() {
            this.dataSourceChange = false
            for (let i = 0; i < this.availableSources.length; i++) {
                let find = this.activeSources.findIndex(x => x.stockFileId == this.availableSources[i].id)
                if (this.availableSources[i].active && find == -1) {
                    this.dataSourceChange = true
                    break
                } else if (!this.availableSources[i].active && find > -1) {
                    this.dataSourceChange = true
                    break
                }
            }
            // if(this.$store.state.user.tagId == 3){
            //     this.dataSourceChange = false
            // }
            this.dataSourceKey++
        },
        formatNumber(number) {
            return number.toLocaleString(undefined, { minimumFractionDigits: 0 });
        },
        async load() {
            this.getAvailableSources()
            if (!this.eventData && this.item.eventUrl) {
                this.eventData = {
                    eventType: this.item.eventUrl.includes('OceanInsight') ? 'OceanInsight' : 'DCSA'
                }
                let eventData = await axios.get(this.item.eventUrl)
                this.eventData.events = eventData.data
                this.eventKey++
            }
            await this.getContainerProductSummary(this.item)
            await this.getContainerTelemetry(this.item)
        },
        async getAvailableSources() {
            this.loadingDataSource = true
            let result = await this.$API.getAvailableStockSources(this.item.id)
            this.activeSources = await this.$API.getActiveStockSources(this.item.id)
            for (let i = 0; i < result.length; i++) {
                let find = this.activeSources.find(x => x.source == result[i].type && x.stockFileId == result[i].id)
                result[i].active = find ? true : false
                if(result[i].sourceType == 'stockFile'){
                    if (typeof result[i].contents == 'string') {
                    let data = await axios.get(result[i].contents)
                    if (data && data.data) {
                        result[i].contents = data.data
                    }
                }
                } else if(result[i].sourceType == 'stockFileContainer'){
                    console.log(result[i].stockFileContainers)
                    for(let j=0;j<result[i].stockFileContainers.length;j++){
                        if (typeof result[i].stockFileContainers[j].contents == 'string') {
                            console.log(result[i].stockFileContainers[j].contents)
                    let data = await axios.get(result[i].stockFileContainers[j].contents)
                    if (data && data.data) {
                        result[i].stockFileContainers[j].contents = data.data
                    }
                    console.log(result[i].stockFileContainers[j].contents)

                }
                    }
                }
            }
            this.availableSources = result
            this.loadingDataSource = false
        },
        async getContainerProductSummary(item) {
            this.productSummary = []
            this.loadingContainerBreakdown = true
            let result = await this.$API.getContainerSummary(item.shipmentId, item.id)
            this.productSummary = result
            this.productSummary = this.productSummary.map(x => ({ ...x, cartonPrice: '?', kgPrice: '?' }))
            this.loadingContainerBreakdown = false
            this.productKey++
        },
        async getContainerTelemetry(item) {
            this.loadingTelemetry = true
            this.telemetry = []
            let result = await this.$API.getContainerTelemetryData(item.id)
            this.telemetry = result
            this.loadingTelemetry = false
            this.telemetryKey++
        },
        async updateDataSource() {
            this.$confirm(`Are you sure you want to update the data source ?`, "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    try {
                        this.savingDataSource = true
                        let active = this.availableSources.filter(x => x.active)
                        console.log(active)
                        // let obj = active.map(x=>({
                        //     source: x.type,
                        //     type: x.type,
                        //     id: x.id,
                        //     sourceType: x.stockFileContainers && x.stockFileContainers.length>0 ? 'stockFileContainer': 'stockFile',
                        //     contents: x.stockFileContainers && x.stockFileContainers.length>0? x.stockFileContainers[0].contents: x.contents
                        // }))
                        let obj = active.map(x=>({
                            source: x.type,
                            type: x.type,
                            id: x.id,
                            sourceType: x.sourceType,
                            contents: x.sourceType == 'stockFileContainer'?x.stockFileContainers[0].contents: x.contents
                        }))
                        console.log(obj)
                        // [{
                        //   source: item.type,
                        //   type: item.type,
                        //   id: item.id,
                        //   sourceType: type,
                        //   contents: item.pallets
                        // }]
                        // let active = this.availableSources.filter(x => x.active)
                        // await this.$API.updateDocumentSource({
                        //     bookingContainerId: container.id,
                        //     source: obj
                        // })
                        await this.$API.updateDocumentSource({
                            bookingContainerId: this.item.id,
                            source: obj
                        })
                        this.dataSourceChange = false
                        this.savingDataSource = false
                        this.$emit('refresh')
                        this.load();
                        this.$message.success('Successfully updated data source!')
                    } catch (e) {
                        console.log(e)
                        this.$message({
                            type: "error",
                            message: "There was an error changing the document source.",
                        });
                        this.savingDataSource = false

                    }

                })

        },
        viewSource(item) {
            this.sourceItem = item
            if(this.sourceItem.sourceType == 'stockFileContainer'){
                this.selectedContainer = this.sourceItem.stockFileContainers[0]
            }
            this.sourceModal = true
        }
    }
}
</script>