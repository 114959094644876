<template>
    <div>
        <v-card flat>
            <v-card-text v-if="events.length == 0" class="text-center">
                <span style="color: grey">No events found.</span>
            </v-card-text>
            <v-card-text v-if="vertical && events.length >0" :style="{height: height, 'overflow-y': 'auto'}">
                <v-timeline align-center dense>
                    <v-timeline-item v-for="event in formatEvents" :key="event.eventId"
                        :color="event.isActual ? 'success' : 'grey'" small>
                        <v-card class="my-p py-0" style="background-color: var(--v-component-base) !important">
                            <v-card-text class="my-0 py-1">
                                <ul style="list-style-type: none">
                                    <li>
                                        <strong>{{ event.description }}</strong>
                                    </li>
                                    <li>
                                        <v-chip pill small v-if="event.location" outlined>
                                            <v-avatar size="32" left>
                                                <v-img contain
                                                    :src="`https://cdn.loglive.io/flags/4x3/${event.location.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                            </v-avatar>
                                            {{ event.location }}
                                        </v-chip>
                                    </li>
                                    <li>
                                        {{ formatDateTime(event.date) }}
                                    </li>
                                    
                                    <li v-if="event.vesselName">
                                        <!-- <v-icon small class="mx-1">{{ getIcon(event.transportCall.modeOfTransport)
                                        }}</v-icon> -->
                                        {{ event.vesselName }} <br />
                                        <p style="font-size: 12px" v-if="event.voyageNo">
                                            Voyage No: {{
                                                event.voyageNo}}
                                        </p>
                                    </li>
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
            <v-card-text v-else-if="!vertical && events.length >0" :style="{width: width}">
                <!-- <v-row>
                    <v-col v-for="(event, index) in formatEvents" :key="event.eventId" class="mx-0 px-0">
                        <div style="width: 100%" class="text-center">
                            <v-chip>{{ index +1 }}</v-chip>
                            </div>

                        </v-col>
                        </v-row> -->
                <!-- <v-row> -->
                    <!-- <v-col cols="2" v-for="(event, index) in formatEvents" :key="event.eventId" class="mx-0 px-0 my-0 py-0"> -->
                        <v-slide-group center-active show-arrows>
                    <v-slide-item v-for="(event, index) in formatEvents" :key="event.eventId"  class="mx-0 px-0">
                        <v-card class="ma-0 pa-0" flat  style="width: 200px; border: 1px solid grey">
                             <div style="width: 100%" class="text-center">

                            <v-btn class="mt-2" icon outlined :color="event.isActual ? 'success' : 'grey'">
                            <v-icon v-if="event.isActual" >check</v-icon>
                            <span v-else>{{ index +1 }}</span>
                            </v-btn>
                            </div>
                            <v-card-text style="font-size: 12px" class="text-left mx-0 px-0 mb-0 pb-0">
                                <ul style="list-style-type: none">
                                    <li>
                                        <strong>{{ event.description }}</strong>
                                    </li>
                                    <li>
                                        <v-chip pill small v-if="event.location" outlined>
                                            <v-avatar size="32" left>
                                                <v-img contain
                                                    :src="`https://cdn.loglive.io/flags/4x3/${event.location.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                            </v-avatar>
                                            {{ event.location }}
                                        </v-chip>
                                    </li>
                                    <li>
                                        {{ formatDateTime(event.date) }}
                                    </li>
                                    <li v-if="event.vesselName || event.voyageNo">
                                        <!-- <v-icon small class="mx-1">{{ getIcon(event.transportCall.modeOfTransport)
                                        }}</v-icon> -->
                                        {{ event.vesselName }} <br />
                                        <p style="font-size: 12px" v-if="event.voyageNo">
                                            Voyage: {{
                                               event.voyageNo }}
                                        </p>
                                    </li>
                                   
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-slide-item>
                    </v-slide-group>
                    <!-- </v-col> -->
                <!-- </v-row> -->
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import dateFormat from 'dateformat'
export default {
    props: {
        vertical: {
            type: Boolean,
            default: true,
        },
        height: {
            type: String,
            default: '75vh',
        },
        width: {
            type: String,
            default: '100%',
        },
        events: {
            type: Array,
            default: () => [],
        }
    },
    data: () => ({
        
    }),
    computed: {
        formatEvents() {
            let result = this.events
            
            result.sort((a, b) => {
                return new Date(a.date) - new Date(b.date)
            })
            console.table(result)
            return result
        }
    },
    methods: {
        formatDateTime(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd mmm, yyyy HH:MM");
            }
            return result;
        },
        getIcon(type) {
            switch (type) {
                case 'VESSEL':
                    return 'directions_boat'
                case 'TRUCK':
                    return 'local_shipping'
                default:
                    return 'help'
            }
        }
    }
}
</script>