<template>
  <div>
    <v-card v-if="item && item.id" flat style="background-color: var(--v-background-base) !important">
      <v-toolbar color="greyBase" dark extended flat extension-height="55">
        <v-btn icon @click="$router.go(-1)"><v-icon>arrow_back</v-icon></v-btn>
        <v-toolbar-title>
          <b class="mr-3">{{ item.containerNo }} {{ item.ctoNo }}</b>
        </v-toolbar-title>
        <v-spacer></v-spacer>
       
        <div v-if="item.ctoNo">
          <v-btn id="edit-button" @click="editPallets" text style="text-transform: none">
            <v-icon class="mr-1">edit</v-icon>
            Edit</v-btn>
        </div>
        <div v-if="!shipmentLocked && availableSources.length>1">
          <v-btn id="edit-button" @click="changeSource" text style="text-transform: none">
            <v-icon class="mr-1">refresh</v-icon>
            Change Document Source
          </v-btn>
        </div>
        <v-chip v-if="shipmentLocked">
        <v-icon left color="amber" small>lock</v-icon>
          Shipment Locked
        </v-chip>
        <div v-if="item.ctoNo">
          <v-btn id="resync-button" :disabled="true" @click="resetContainerSync(item)" text style="text-transform: none">
            <v-icon v-if="item.synced" class="mr-1">sync</v-icon>
            <v-icon v-else class="mr-1">sync_disabled</v-icon>
            Re-Sync</v-btn>
        </div>
        <v-btn id="info-guide" text @click="infoStartGuide" height="4vh" width="4vh">
          <v-icon>help</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
        'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
        'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
      }">
        <v-card-text :style="{
          height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
          'overflow-y': 'auto',
        }">
          <v-tabs v-model="selectedTab" show-arrows style="background: grey" icons-and-text height="60px">
            <v-tab v-for="tab in tabs" :key="tab" :href="'#' + tab.replace(/ /g, '')" class="pb-3">
              {{ tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item value="Overview">
              <Overview :item="item" :shipmentId="shipmentId" @refresh="load($route.params.id)" />
            </v-tab-item>
            <v-tab-item value="Contents">
              <ContainerContent :item="item" />
            </v-tab-item>

            <!-- <v-tab-item value="PPECBTempData">
              <PPECBTempDataContent />
            </v-tab-item> -->

            <v-tab-item value="Documents">
              <Document :item="item" :shipmentId="shipmentId" @refresh="load($route.params.id)" />
            </v-tab-item>

            <!-- <v-tab-item value="Telemetry">
              <Telemetry :item="item" />
            </v-tab-item> -->
            <!-- 
            <v-tab-item value="Temperature">
              <sensorTemperature :container="item" />
            </v-tab-item>

            <v-tab-item value="Light">
              <sensorLight :container="item" />
            </v-tab-item> -->
          </v-tabs-items>

        </v-card-text>
      </v-card>

      <v-dialog v-model="palletModal" :fullscreen="acceptLiability" width="600px">
        <v-card>
          <v-card-title>
            Manage Container Contents
            <v-spacer></v-spacer>
            <v-badge :key="badgeKey" :content="badgeCount()" :value="badgeCount() > 0" color="primary" offset-y="10">
              <v-btn v-if="acceptLiability" icon color="secondary"
                @click="filterModal = true"><v-icon>filter_alt</v-icon></v-btn>
            </v-badge>


            <v-btn v-if="acceptLiability" icon color="primary" @click="saveChanges()"
              :loading="savingPalletChanges"><v-icon>save</v-icon></v-btn>

            <v-btn icon
              @click="resetChanges(), palletModal = false, acceptLiability = false"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text v-if="!acceptLiability">
            <v-card-text>
              <p>
                By clicking the "Accept" button below, you acknowledge and agree that any modifications made to the
                contents of the container must be documented with relevant supporting materials. Failure to do so may
                result in criminal liability and/or termination of employment. Please note that all changes will be
                recorded and associated with the responsible party.
              </p>
            </v-card-text>
            <v-card-actions class="text-center">
              <v-row justify="center">
                <v-btn class="mx-2" @click="palletModal = false">Cancel</v-btn>
                <v-btn class="mx-2" color="primary" @click="acceptLiability = true">Accept</v-btn>
              </v-row>

            </v-card-actions>
          </v-card-text>
          <v-card-text v-else :key="tableKey">
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Gross Weight
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip @click="editWeight('grossWeight')">
                      <span>{{ calculateGrossWeight }} KG</span>
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Nett Weight
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip @click="editWeight('nettWeight')"><span>{{ calculateNettWeight }} KG</span></v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-btn class="mt-2" style="text-transform: none" @click="editPackWeight()">Set Pack Code Weight</v-btn>
              </v-col>

            </v-row>
            <table width="100%">
              <th v-for="(head, index) in headers" :width="head.width" :key="index" style="border: 0.5px solid grey">{{
                head.text }} <v-btn small icon v-if="head.text != 'SSCC' && head.text != 'Com' && head.text != 'Var'"
                  @click="massEdit(head)"><v-icon small>find_replace</v-icon></v-btn></th>
              <tbody>
                <tr v-for="pallet in filteredPallets" :key="pallet.id">
                  <td class="text-center"><span style="padding: 0;margin: 0; font-size: 12px;" class="ma-0 pa-0">{{
                    pallet.sscc }}</span> </td>
                  <td class="text-center">{{ pallet.farm }}</td>
                  <td class="text-center">{{ pallet.packHouse }}</td>

                  <td><v-text-field dense v-model="pallet.orchard" style="padding: 0;margin: 0; font-size: 12px;" flat
                      solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.phytoReference" style="padding: 0;margin: 0; font-size: 12px;"
                      flat solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.productionArea" style="padding: 0; margin: 0; font-size: 12px;"
                      flat solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td class="text-center">
                    {{ pallet.product ? pallet.product.code : null }}
                  </td>
                  <td class="text-center">
                    <v-btn style="text-transform: none; font-weight: bold" x-small class="ma-0"
                      @click="editVariety(pallet)">
                      {{ pallet.varietyProduct ? pallet.varietyProduct.code : null }}
                    </v-btn>
                  </td>
                  <td><v-text-field dense v-model="pallet.fleshColour" style="padding: 0;margin: 0; font-size: 12px;" flat
                      solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.gradeClassCode" style="padding: 0;margin: 0; font-size: 12px;"
                      flat solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.countSize" style="padding: 0;margin: 0; font-size: 12px;" flat
                      solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.noCartons" type="number"
                      style="padding: 0;margin: 0; font-size: 12px;" flat solo class="ma-0 pa-0"
                      hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.packCode" style="padding: 0;margin: 0; font-size: 12px;" flat
                      solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td>
                    <v-select style="padding: 2px;margin: 0; font-size: 12px;" flat class="ma-0 pa-0" dense hide-details
                      v-model="pallet.packType" :items="['CARTON', 'BIN','BAG']"></v-select>
                    <!-- <v-text-field dense v-model="pallet.packType" style="padding: 0;margin: 0; font-size: 12px;" flat
                      solo class="ma-0 pa-0" hide-details></v-text-field> -->
                  </td>
                  <td><v-text-field dense v-model="pallet.nettWeight" style="padding: 0;margin: 0; font-size: 12px;" flat
                      solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.grossWeight" style="padding: 0;margin: 0; font-size: 12px;" flat
                      solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.stuffDate" style="padding: 0;margin: 0; font-size: 12px;" flat
                      solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.inspectionDate" style="padding: 0;margin: 0; font-size: 12px;"
                      flat solo class="ma-0 pa-0" hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.inspectionManifestNo"
                      style="padding: 0;margin: 0; font-size: 12px;" flat solo class="ma-0 pa-0"
                      hide-details></v-text-field></td>
                  <td><v-text-field dense v-model="pallet.inspectionPoint" style="padding: 0;margin: 0; font-size: 12px;"
                      flat solo class="ma-0 pa-0" hide-details></v-text-field></td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="massEditModal" width="400px">
        <v-card v-if="editItem && editItem.header">
          <v-card-title>
            Edit {{ editItem.header.text }}
            <v-spacer></v-spacer>
            <v-btn icon color="primary" :disabled="!editItem.value" @click="saveMassChange"><v-icon>save</v-icon></v-btn>
            <v-btn text @click="massEditModal = false, editItem = {}">X</v-btn>
          </v-card-title>
          <v-card-text>
            <v-text-field v-if="editItem.header.value != 'packType'" v-model="editItem.value" outlined
              placeholder="Enter new value"></v-text-field>
            <v-select v-if="editItem.header.value == 'packType'" v-model="editItem.value" :items="['CARTON', 'BIN','BAG']"
              outlined placeholder="Select new value"></v-select>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="filterModal" width="600px">
        <v-card>
          <v-card-title>
            Filter
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" plain depressed elevation="0" @click="clearFilters">
                  <v-icon>filter_alt_off</v-icon>
                </v-btn>
              </template>
              <span>Clear Filters</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text color="blue" @click="applyFilter">
                  <v-icon>filter_alt</v-icon>
                </v-btn>
              </template>
              <span>Apply Filters</span>
            </v-tooltip>
            <v-btn text @click="filterModal = false">X</v-btn>
          </v-card-title>
          <v-card-text>
            <v-list dense style="max-height: 80vh; overflow-y: auto">
              <v-list-item style="height: 35px" v-for="(header, index) in headers" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ header.text }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-action>
                  <v-select :items="uniqueOptions(header.value)" style="height: 30px; width: 180px" outlined dense
                    v-model="filterObject[header.value]" multiple clearable>
                    <template v-slot:selection="data">
                      <v-chip class="my-0" small v-if="filterObject[header.value].length == 0" v-bind="data.attrs">
                        None selected
                      </v-chip>
                      <v-chip class="my-0" small v-else-if="data.index === 0">{{ `${filterObject[header.value].length}
                        selected`
                      }}</v-chip>
                    </template>
                  </v-select>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>


      <v-dialog v-model="weightModal" width="600px">
        <v-card v-if="weightItem">
          <v-card-title>Edit Weight
            <v-spacer></v-spacer>
            <v-btn icon @click="updateWeight()"><v-icon>save</v-icon></v-btn>
            <v-btn text @click="weightModal = false, weightItem = {}">X</v-btn>
          </v-card-title>
          <v-card-subtitle>
            This value will be divided proportionally among all pallets.
          </v-card-subtitle>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Gross Weight
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ weightItem.currentGross }} KG
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field outlined v-model="weightItem.newGross" suffix="KG" label="Gross Weight"></v-text-field>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Nett Weight
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ weightItem.currentNett }} KG
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field outlined v-model="weightItem.newNett" suffix="KG" label="Nett Weight"></v-text-field>
                </v-list-item-action>
              </v-list-item>
            </v-list>

          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="varietyModal" width="1200px">
        <v-card v-if="varietyItem">
          <v-card-title>Manage Variety <v-spacer></v-spacer><v-btn text
              @click="varietyModal = false, varietyItem = {}">X</v-btn> </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item v-if="varietyItem && varietyItem.product">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ varietyItem.product.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Product
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6">
                <v-list-item v-if="varietyItem && varietyItem.varietyProduct">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ varietyItem.varietyProduct.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Variety
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Search" prepend-inner-icon="search" v-model="productParams.search" outlined dense
                  clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox v-model="varietyItem.applyAll" label="Apply to all"></v-checkbox>
              </v-col>


            </v-row>
            <v-data-table class="mt-3" dense disable-pagination fixed-header hide-default-footer
              :loading="loadingProducts" @click:row="updateVariety" :items="products.data" :headers="productHeaders"
              height="45vh" :calculate-widths="true" style="cursor: pointer">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-btn icon small>
                    <v-icon small @click="editItem(item)">edit</v-icon>
                  </v-btn>
                  <v-btn icon small color="danger" @click="deleteItem(item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </v-row>
              </template>

              <template v-slot:[`item.trademark`]="{ item }">
                <v-icon v-if="item.trademark" color="success">check</v-icon>
                <v-icon v-else color="danger">close</v-icon>
              </template>

              <template v-slot:[`item.patent`]="{ item }">
                <v-icon v-if="item.patent" color="success">check</v-icon>
                <v-icon v-else color="danger">close</v-icon>
              </template>
            </v-data-table>
            <v-col cols="12" class="text-center">
              <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="productPage" :pager-count="5"
                :page-size.sync="productParams.limit" :page-sizes="[12, 25, 50, 100]" :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'prev, pager, next'
                  " :total="products.total">
              </el-pagination>
            </v-col>
          </v-card-text>
        </v-card></v-dialog>

      <v-dialog v-model="packCodeModal" width="600px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
        <v-card>
          <v-toolbar flat color="transparent">
            <v-toolbar-title>
              Set Pack Code Weights
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text color="primary" icon @click="setPackCodeWeights()"><v-icon>save</v-icon></v-btn>
            <v-btn text @click="packCodeModal = false, uniquePackCodes = []">X</v-btn>
          </v-toolbar>
          <v-card-subtitle>
            These are the weights per carton/item.
          </v-card-subtitle>
          <v-card-text>
            <v-row v-for="(packCode, index) in uniquePackCodes" :key="index">
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field outlined :label="packCode.name + ' NETT'" v-model="packCode.nettWeight" dense
                  suffix="KG"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-text-field outlined :label="packCode.name + ' GROSS'" v-model="packCode.grossWeight" dense
                  suffix="KG"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>


      <v-dialog v-model="sourceModal" fullscreen persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Manage Document Source
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="green" small outlined v-if="selectedSource && selectedSource.id && activeSource && activeSource.id != selectedSource.id" :loading="settingActiveSource" @click="setActiveSource()">Set as active source</v-btn>
          <v-btn text @click="sourceModal=false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
              <v-list dense>
                <v-subheader style="font-size: 18px">Stock Sources</v-subheader>
                <v-divider></v-divider>
                <v-list-item @click="selectedSource = source" v-for="source in availableSources" :key="source.id" :style="{'border-left': selectedSource && selectedSource.id == source.id ? '3px solid var(--v-primary-base)': ''}">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ source.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ source.type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip small color="blue" v-if="activeSource && activeSource.id == source.id">
                    Active
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="9">
              <v-data-table v-if="selectedSource && selectedSource.id" :items="selectedSource.contents" :headers="importHeaders" height="85vh" fixed-header dense disable-pagination hide-default-footer></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      </v-dialog>
    </v-card>

    <div v-else style="
                    height: 80vh;
                    padding-top: 0;
                    margin-top: 0;
                    background: transparent;
                  " class="text-center">
      <valhalla-loading />
    </div>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Overview!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Help Guide/ Tutorial -->

  </div>
</template>
<script>

import Document from './ContainerSummary/Document.vue'
import ContainerContent from './ContainerSummary/ContainerContent.vue'
// import Document from './ContainerSummary/Document.vue'
import Overview from './ContainerSummary/Overview.vue'
// import Telemetry from './ContainerSummary/Telemetry.vue';

import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
// import steps from './Steps/viewContainerSteps'
import axios from 'axios';

export default {
  components: { ContainerContent, Document, Overview },
  data: () => ({
    steps: null,
    activeSource: null,
    acceptLiability: false,
    availableSources: [],
    badgeKey: 2000,
    editItem: {},
    filterModal: false,
    filterObject: {},
    filteredPallets: [],
    headers: [
      {
        text: "SSCC",
        value: "sscc",
        width: "40px",
        sortable: false,
        align: "center",
      },
      {
        text: "PUC",
        value: "farm",
        sortable: false,
        width: "45px",
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        width: "45px",
        sortable: false,
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        width: "60px",
        sortable: false,
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        width: "70px",
        sortable: false,
        align: "center",
      },

      {
        text: "Production Area",
        value: "productionArea",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Com",
        value: "productId",
        sortable: false,
        width: '35px',
        align: "center",
      },
      {
        text: "Var",
        value: "varietyProductId",
        width: '35px',
        sortable: false,
        align: "center",
      },
      {
        text: "Flesh Colour",
        value: "fleshColour",
        width: '70px',
        sortable: false,
        align: "center",
      },
      {
        text: "Class",
        value: "gradeClassCode",
        width: "50px",
        sortable: false,
        align: "center",
      },
      {
        text: "Count",
        value: "countSize",
        width: "50px",
        sortable: false,
        align: "center",
      },
      {
        text: "Cartons",
        value: "noCartons",
        sortable: false,
        width: "60px",
        align: "right",
      },
      {
        text: "Pack Code",
        value: "packCode",
        sortable: false,
        width: "60px",
        align: "center",
      },
      {
        text: "Pack Type",
        value: "packType",
        sortable: false,
        width: "60px",
        align: "center",
      },
      {
        text: "Nett",
        value: "nettWeight",
        width: "75px",
        sortable: false,
        align: "left",
      },
      {
        text: "Gross",
        value: "grossWeight",
        width: "75px",
        sortable: false,
        align: "left",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "70px",
        sortable: false,
        align: "center",
      },
      {
        text: "Insp. Date",
        value: "inspectionDate",
        width: "70px",
        sortable: false,
        align: "center",
      },
      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        width: "70px",
        sortable: false,
        align: "center",
      },
      {
        text: "Insp. Point",
        value: "inspectionPoint",
        width: "65px",
        sortable: false,
        align: "center",
      },
    ],
    importHeaders: [],
    itemKey: 0,
    item: {},
    massEditModal: false,
    originalPallets: [],
    packCodeModal: false,
    pallets: [],
    palletModal: false,
    products: {
      total: 0,
      data: []
    },
    loadingProducts: false,
    productParams: {
      limit: 12,
      search: null,
      filter: {
        type: 'Variety'
      }
    },
    productTimer: null,
    productHeaders: [
      {
        text: "Type",
        value: "type",
        align: "left",
      },
      {
        text: "Parent",
        value: "parentProduct.name",
        align: "left",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
      },
      {
        text: "HS Code",
        value: "hsCode.alternateCode",
        align: "left",
      },

      {
        text: "Codes",
        value: "codes",
        align: "center",
      },

      {
        text: "Description",
        value: "description",
        align: "left",
      },
      {
        text: "Trademark",
        value: "trademark",
        align: "center",
      },
      {
        text: "Patent",
        value: "patent",
        align: "center",
      },

    ],
    productPage: 1,
    savingPalletChanges: false,
    selectedTab: 'Overview',
    selectedSource: {},
    settingActiveSource: false,
    sourceModal: false,
    shipmentId: null,
    shipmentLocked: false,
    tabs: ['Overview', 'Contents', 'Documents'],
    tableKey: 1000,
    titanVerification: [],
    uniquePackCodes: [],
    varietyModal: false,
    varietyItem: {},
    weightModal: false,
    weightItem: {},
    infoStart: false,
  }),
  watch: {
    // 'item.sensors': {
    //   immediate: true,
    //   handler(val) {
    //     if (val && val.length > 0) {
    //       this.tabs.push('Telemetry')
    //     }
    //   }
    // },
    async productPage(value) {
      this.productParams.offset = (value - 1) * this.productParams.limit;
      await this.getProducts();
    },
    "productParams.limit": {
      immediate: true,
      async handler() {
        this.productPage = 1;
        await this.getProducts();
      },
    },
    "productParams.search": {
      async handler() {
        await this.getProducts();
      },
    },
    "$route.params.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.load(val);
          this.shipmentId = this.$route.params.shipmentId
        }
      },
    },
  },
  computed: {
    calculateGrossWeight() {
      let result = this.pallets.map(x => parseFloat(x.grossWeight)).reduce((a, b) => a + b, 0)
      return result.toFixed(2)
    },
    calculateNettWeight() {
      let result = this.pallets.map(x => parseFloat(x.nettWeight)).reduce((a, b) => a + b, 0)
      return result.toFixed(2)
    }
  },
  async created() {
    this.importHeaders = await this.$API.getMappingHeaders()
    this.importHeaders = this.importHeaders.map(x=>({
      text: x.name,
      value: x.field,
      align: 'center'
    }))
    for (let i = 0; i < this.headers.length; i++) {
      this.filterObject[this.headers[i].value] = []
    }
    await this.getSteps();
  },
  async mounted() {
    this.driver = new Driver({
      animate: false
    })
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'Shipments/ContainerView/Steps',
        });
        this.steps = steps.steps
        },
    applyFilter() {
      let pallets = this.item.containerPallets
      let keys = Object.keys(this.filterObject)
      for (let i = 0; i < keys.length; i++) {
        if (this.filterObject[keys[i]].length > 0) {
          if (keys[i] == 'productId') {
            pallets = pallets.filter(x => this.filterObject[keys[i]].includes(x.product.name))
          } else if (keys[i] == 'varietyProductId') {
            pallets = pallets.filter(x => this.filterObject[keys[i]].includes(x.varietyProduct.name))
          } else {
            pallets = pallets.filter(x => this.filterObject[keys[i]].includes(x[keys[i]]))
          }
        }
      }
      this.badgeKey++
      this.filterModal = false
      this.filteredPallets = pallets
    },
    badgeCount() {
      let keys = Object.keys(this.filterObject)
      let count = keys.filter(x => this.filterObject[x].length > 0).length
      return count
    },
    changeSource(){
      this.sourceModal = true
    },
    clearFilters() {
      for (let i = 0; i < this.headers.length; i++) {
        this.filterObject[this.headers[i].value] = []
      }
    },
    editPackWeight() {
      this.uniquePackCodes = [...new Set(this.filteredPallets.map(x => x.packCode))].map(x => ({ name: x, nettWeight: null, grossWeight: null, variance: 0.3 }))
      this.packCodeModal = true
    },
    editPallets() {
      this.pallets = this.item.containerPallets
      this.originalPallets = this.pallets.map(object => ({ ...object }))
      this.filteredPallets = this.pallets
      this.palletModal = true
    },
    editVariety(item) {
      this.varietyItem = {
        id: item.id,
        current: item.variety,
        commodity: item.commodity,
        productId: item.productId,
        product: item.product,
        varietyProductId: item.varietyProductId,
        varietyProduct: item.varietyProduct,
        applyAll: true,
      }
      this.varietyModal = true
      this.productParams.filter.productId = item.productId
      this.getProducts()
    },
    editWeight(type) {
      this.weightItem = {
        type: type == 'grossWeight' ? 'Gross' : 'Nett',
        currentGross: this.calculateGrossWeight,
        currentNett: this.calculateNettWeight,
        newNett: this.calculateNettWeight,
        newGross: this.calculateGrossWeight
      }
      this.weightModal = true
    },
    finalDestinationCode(code) {
      return code ? code.substring(0, 2) : null
    },
    async finalDestinationRegion(code) {
      let countryCode = this.finalDestinationCode(code)
      let result = await this.$API.getCountryByCode(countryCode)
      return result ? result.regionCode : null
    },
    async getProducts() {
      if (this.productTimer) {
        clearTimeout(this.productTimer);
      }
      this.loadingProducts = true;
      this.productTimer = setTimeout(async () => {
        this.products = await this.$API.getProducts({
          params: this.productParams,
        });
        this.loadingProducts = false;
      }, 500);
    },
    async load(val) {
      this.item = await this.$API.getContainer(val);
      this.shipmentLocked = await this.$API.isShipmentLocked(this.shipmentId)
      if (!this.item.ppecb) {
        this.item.ppecb = {}
      }
      this.item.booking.targetRegion = await this.finalDestinationRegion(this.item.booking.bookingFinalDestinationCode)
      // if (
      //   !this.item.containerPallets
      //     .map((x) => x.commodity)
      //     .includes("NECTARINE") ||
      //   this.item.containerPallets.map((x) => x.commodity).includes("PEACH")
      // ) {
      //   this.headers = this.headers.filter((x) => x.value != "fleshColour");
      // }
      // if (
      //   this.item.containerPallets.map((x) => x.commodity).includes("AVOCADOS")
      // ) {
      //   this.headers = this.headers.filter((x) => x.value != "phytoData");
      // }
    },
    massEdit(header) {
      this.editItem = {
        header: header
      }
      this.massEditModal = true
    },
    async resetContainerSync(container) {
      let res = await this.$API.resetContainerSync(container.id)
      if (res[0] > 0) {
        this.$message({
          type: "success",
          message: "Successfully updated Sync Flag!",
        });
        this.item.synced = 0
      } else {
        this.$message({
          type: "warning",
          message: "Unable to update Container Sync Flag.",
        });
      }
    },
    resetChanges() {
      this.item.containerPallets = this.originalPallets
    },
    async saveChanges() {
      this.savingPalletChanges = true
      let changes = []
      for (let i = 0; i < this.pallets.length; i++) {
        let original = this.originalPallets[i]
        let current = this.pallets[i]
        for (let j = 0; j < this.headers.length; j++) {
          if (original[this.headers[j].value] != current[this.headers[j].value]) {
            changes.push({
              ...current,
              lastEditedById: this.$store.state.user.id
            })
          }
        }
      }
      if (changes.length > 0) {
        await this.$API.bulkUpdatePallets(changes)
      }
      this.palletModal = false
      this.savingPalletChanges = false
    },
    saveMassChange() {
      for (let i = 0; i < this.filteredPallets.length; i++) {
        this.filteredPallets[i][this.editItem.header.value] = this.editItem.value
        this.filteredPallets[i].lastEditedById = this.$store.state.user.id
      }
      this.massEditModal = false
      this.editItem = {}
    },
    setActiveSource(){
      this.$confirm(`Are you sure you want to change the document source from "${this.activeSource.type}" to "${this.selectedSource.type}"?`, "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                  try {
                    this.settingActiveSource = true
                  let result = await this.$API.updateDocumentSource({
                    bookingContainerId: this.item.id,
                    source: this.selectedSource
                  })
                  console.log(result)
                  this.sourceModal = false
                  this.settingActiveSource = false

                  this.load(this.$route.params.id);
                  this.$message.success('Successfully updated document source!')
                  } catch(e){
                    this.$message({
                      type: "error",
                      message: "There was an error changing the document source.",
                    });
                  this.settingActiveSource = false
                  }
               
                })
    },
    setPackCodeWeights() {
      let weights = this.uniquePackCodes.filter(x => x.nettWeight || x.grossWeight)
      weights = weights.map(x => { return { name: x.name, nettWeight: x.nettWeight ? parseFloat(x.nettWeight) : null, grossWeight: x.grossWeight ? parseFloat(x.grossWeight) : null } })
      // console.log(weights)
      for (let i = 0; i < weights.length; i++) {
        let filtered = this.filteredPallets.filter(x => x.packCode == weights[i].name && x.noCartons)
        for (let j = 0; j < filtered.length; j++) {
          if (weights[i].nettWeight) {
            filtered[j].nettWeight = Math.round(weights[i].nettWeight * filtered[j].noCartons * 100) / 100

          }
          if (weights[i].grossWeight) {
            filtered[j].grossWeight = Math.round(weights[i].grossWeight * filtered[j].noCartons * 100) / 100

          }

          // console.log('Set weight', filtered[j].sscc, filtered[j].grossWeight, filtered[j].nettWeight)
        }
      }
      this.packCodeModal = false
      this.uniquePackCodes = []
    },
    async trackContainer(container) {
      this.container = container;
      this.containerModal = true;
      this.container.containerDetail = await this.$API.trackContainer({
        params: {
          containerNumber: container.containerNo,
        },
      });
      this.container.containerDetail = this.container.containerDetail[0];
      this.containerKey++;
    },
    uniqueOptions(field) {
      if (field == 'productId') {
        let result = [...new Set(this.pallets.filter(x => x.product).map(x => x.product.name))]
        return result
      } else if (field == 'varietyProductId') {
        let result = [...new Set(this.pallets.filter(x => x.varietyProduct).map(x => x.varietyProduct.name))]
        return result
      } else {
        let result = [...new Set(this.pallets.map(x => x[field]))]
        return result
      }
    },
    updateWeight() {
      let unique = [...new Set(this.pallets.map(x => x.sscc))]

      if (this.weightItem.currentGross != this.weightItem.newGross) {
        let calculatedWeight = parseFloat(this.weightItem.newGross) / unique.length
        for (let i = 0; i < unique.length; i++) {
          let filtered = this.pallets.filter(x => x.sscc == unique[i])
          let totalCartons = filtered.map(x => parseInt(x.noCartons)).reduce((a, b) => a + b)
          for (let j = 0; j < filtered.length; j++) {
            filtered[j].grossWeight = (Math.round((calculatedWeight * ((parseInt(filtered[j].noCartons)) / totalCartons)) * 100) / 100).toFixed(2)
          }
        }
      }
      if (this.weightItem.currentNett != this.weightItem.newNett) {
        let calculatedWeight = parseFloat(this.weightItem.newNett) / unique.length
        for (let i = 0; i < unique.length; i++) {
          let filtered = this.pallets.filter(x => x.sscc == unique[i])
          let totalCartons = filtered.map(x => parseInt(x.noCartons)).reduce((a, b) => a + b)
          for (let j = 0; j < filtered.length; j++) {
            filtered[j].nettWeight = (Math.round((calculatedWeight * ((parseInt(filtered[j].noCartons)) / totalCartons)) * 100) / 100).toFixed(2)
          }
        }
      }
      this.weightModal = false
      this.weightItem = {}
    },
    updateVariety(item) {
      let filter = this.filteredPallets.filter(x => x.varietyProductId == this.varietyItem.varietyProductId && x.productId == this.varietyItem.productId)
      if (this.varietyItem.applyAll) {
        for (let i = 0; i < filter.length; i++) {
          filter[i].varietyProductId = item.id
          filter[i].varietyProduct = item
          filter[i].variety = item.code
          filter[i].varietyName = item.name
        }
      } else {
        let find = this.filteredPallets.find(x => x.id == this.varietyItem.id)
        find.varietyProductId = item.id
        find.varietyProduct = item
        find.variety = item.code
        find.varietyName = item.name
      }
      this.varietyModal = false
      this.varietyItem = {}
    },
    verify(type, metadata) {
      return metadata.map(x => x.key).includes(type)
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(this.steps)
      this.driver.start()
    },
  },
};
</script>
<style >
/* .auditLine {
  background-color: #e34602;
} */
th {
  font-size: 12px;
  padding: 0;
  margin: 0
}

td {
  font-size: 12px;

}

td.fitwidth {
  width: 1px;
  white-space: nowrap;
}
</style>
